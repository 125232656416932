@import "node_modules/@heer.dev/una-theme-kit/dist/scss/base"; // una.css

// VARIABLES
:root {
  --max-width: 1280px;
  --family-1: 'Roboto', sans-serif;
  --family-2: 'Roboto', sans-serif;
  --font-size-1: .8rem;
  --font-size-2: .95rem;
  --font-size-3: 1.05rem;
  --font-size-4: 1.2rem;
  --line-height-1: 1;
  --line-height-2: 1.7;
  --line-height-3: 2.25;
  --height-1: 50px;
  --height-2: 70px;
  --height-3: 100px;
  --space-1: 1rem;
  --space-2: 1.5rem;
  --space-3: 3rem;
  --space-around: 3rem 1.5rem;
  --border-radius-1: 3px;
}

@media (min-width: 1180px) {
  :root {
    --space-around: 5vh;
  }
}

// Theme Colors
.light {
  --color-1: hsl(27, 59%, 21%);
  --color-2: hsl(11, 45%, 51%);
  --on-color: hsl(45, 41%, 100%);
  --background-1: hsl(0, 0%, 98%);
  --background-2: hsl(0, 0%, 100%);
  --background-3: hsl(39, 43%, 97%);
  --on-background: hsl(0, 0%, 50%);
  --border: hsl(0, 0%, 90%);
  --error: #c42600;
  --warning: #d38f00;
  --success: #009600;
}// MEDIA QUERIES
@media (min-width: 768px) {} // Tablets
@media (min-width: 1180px) {} // Laptops
@media (min-width: 2561px) {} // 4k

// CUSTOM
body {
  font-weight: 400;
  color: var(--color-1);
  ul {
    margin: 0 0 0 1rem;
    padding: 0;
  }
  h1 {
    line-height: var(--line-height-2);
    font-size: var(--font-size-4);
  }
}

li {line-height: var(--line-height-2);}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
}
